import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'

const EnterpriseDealDeskAnalyst = () => {
  return (
    <>
    <SEO title="vacancies"/>
    <Layout>
        <article className='co-page'>
                <section className="co-bullet typical__padding">
                    <div className="section-container">
                        <h2 className="section-title">
                            Enterprise Deal Desk Analyst
                        </h2>
                        <p className="co-bullet-info">
                            We are a team of payment technologies seeking passionate professionals to reach new heights. DigiMeth is currently in search of an Enterprise Deal Desk Analyst.
                        </p>
                        <div className="co-vacancies--grid">
                            <h3 className='co-bullet-title'>About DigiMeth</h3>
                            <p className="co-bullet-info">
                                Digital Method is a software development company specialising in payment technology. Our mission is to provide fast, high-quality, and diverse online payment experience to our partners and their customers. We provide international and local payments for all business types.
                            </p>
                            <h3 className='co-bullet-title'>What You’ll Do</h3>
                            <ul className='co-bullet-list'>
                                <li>Provide support and guidance to Sales on best practices for deal management and contract structuring.</li>
                                <li>Shepherd deals through the sales cycle and internal approval process in collaboration with the appropriate cross-functional teams</li>
                                <li>Identify and document deal-making policies in support of the contracting process</li>
                                <li>Serve as point of contact and subject matter expert on deal policies, including contract terms, fallbacks, product configurations, and business terms</li>
                                <li>Collaborate with cross-functional teams (Systems, Legal, Pricing, etc.), providing advice and deal insights to build scalable, efficient processes; drive improvements in policies, processes, and systems to help increase Sales productivity.
                                </li>
                            </ul>

                            <h3 className='co-bullet-title'>What you`ll need</h3>
                            <ul className='co-bullet-list'>
                                <li>5+ years of experience in a deal support role, ideally in a tech environment</li>
                                <li>Ability to assess problems in a structured fashion and identify the key issues to solve</li>
                                <li>Work well under pressure, with ruthless prioritization along with timely and transparent communication</li>
                                <li>Excellent written and verbal communication skills</li>
                                <li>Ability to partner with Sales and other internal cross-functional teams, such as Finance, Legal, and Pricing</li>
                                <li>An eye for detail but also an ability to refer back to the big picture</li>
                            </ul>

                            <h3 className='co-bullet-title'>Nice to have</h3>
                            <ul className='co-bullet-list'>
                                <li>Knowledge of Customer Relationship Manager (CRM) systems</li>
                                <li>Proficiency in Salesforce</li>
                                <li>Proficiency in contracting negotiations</li>
                            </ul>

                            <p className="co-bullet-info">Work schedule - 5/2, 09:00-18:00 (aligned with banking system operations).</p>

                            <div className='co-join-container'>
                                <span>If you are interested in working with us, send your CV here</span>{' '}
                                <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                            </div>
                        </div>
                    </div>
                </section>
                <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
                <Contact/>
            </article>
        </Layout>
    </>
  )
}

export default EnterpriseDealDeskAnalyst